import React, { FC, ReactElement, useCallback, useMemo, useState } from 'react'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from '../../../hooks/useTranslation'
import {
  Contact,
  ContactFilter,
  Maybe,
  PaginationParams,
  useCustomersQuery,
  useDeleteCustomerMutation,
  useDeleteMerchantMutation,
  useMerchantsCustomersQuery,
  useMerchantsQuery,
} from '../../../../api/models'
import { CsButton, FixedActionBar } from '@csinstruments/cs-react-theme'
import { DataTable } from '../../partials/DataTable/DataTable'
import { DataItemAction, DataTableColumn, DataTableRow } from '../../partials/DataTable/DataTableTypes'
import { Add, Description } from '@material-ui/icons'
import { ROUTES } from '../../../helpers/routes'
import { PageHeading } from '../../partials/PageHeading'
import { Grid } from '@material-ui/core'
import { PaginationInfoLimitUpdater } from '../../partials/Pagination/PaginationInfoLimitUpdater'
import { LicenseServerPagination } from '../../partials/Pagination/LicenseServerPagination'
import Search from '../../partials/Inputs/Search'
import { StandardConfirmationModal } from '../../partials/StandardConfirmationModal/StandardConfirmationModal'
import { createCsvExport } from '../../../helpers/csvExport'
import { usePushDownloadFile } from '../../../hooks/usePushDownloadFile'
import { useRecoilState } from 'recoil'
import { paginationState } from '../../../helpers/recoil'

const contactsToRows = (
  contacts: (
    | Maybe<
        { __typename?: 'Contact' | undefined } & Pick<
          Contact,
          | 'city'
          | 'company'
          | 'country_code'
          | 'email'
          | 'fax'
          | 'first_name'
          | 'last_name'
          | 'middle_name'
          | 'mobile'
          | 'note'
          | 'phone'
          | 'position'
          | 'post_code'
          | 'salutation'
          | 'state'
          | 'street'
          | 'website'
          | 'customerid'
        >
      >
    | undefined
  )[],
  ids: string[],
  nLicenses: number[],
): DataTableRow[] => {
  return contacts.map((contact, i) => {
    return {
      id: ids[i],
      values: [
        `${contact?.customerid || ''}`,
        contact?.company ? ' ' + contact.company : '',
        `${contact?.street} ${contact?.post_code} ${contact?.city}${
          contact?.country_code ? ', ' + contact?.country_code : ''
        }`,
        `${nLicenses[i]}`,
      ],
    }
  })
}

export const ListContacts: FC = ({}): ReactElement => {
  const location = useLocation()
  const { t } = useTranslation()
  const history = useHistory()
  const isCustomerPage = useMemo(() => location.pathname.includes('customer') && !location.pathname.includes('-'), [
    location,
  ])
  const isMerchantPage = useMemo(() => location.pathname.includes('merchant') && !location.pathname.includes('-'), [
    location,
  ])
  const isMerchantCustomerPage = useMemo(() => location.pathname.includes('-'), [location])
  const [paginationData, setPaginationData] = useState<PaginationParams>({ limit: 10, Offset: 0 })
  const [selectedID, setSelectedID] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [deleteCustomerMutation] = useDeleteCustomerMutation()
  const [deleteMerchantMutation] = useDeleteMerchantMutation()
  const { downloadAction } = usePushDownloadFile()
  const [filter, setFilter] = useState<ContactFilter>({})
  const [newFilter, setNewFilter] = useState<ContactFilter>({})
  const [_, setCurrentPage] = useRecoilState(paginationState)

  const { data: customerData, refetch: refetchCustomers } = useCustomersQuery({
    skip: !isCustomerPage,
    variables: { paginationParams: paginationData, filter },
  })
  const { data: merchantData, refetch: refetchMerchants } = useMerchantsQuery({
    skip: !isMerchantPage,
    variables: { paginationParams: paginationData, filter },
  })
  const { data: merchantCustomerData, refetch: refetchMerchantsCustomers } = useMerchantsCustomersQuery({
    skip: !isMerchantCustomerPage,
    variables: { paginationParams: paginationData, filter },
  })

  const nEntries = useMemo(() => {
    let entries = 0
    if (customerData) {
      entries = customerData.customers.total
    }
    if (merchantData) {
      entries = merchantData.merchants.total
    }
    if (merchantCustomerData) {
      entries = merchantCustomerData.merchantsCustomers.total
    }
    console.log({
      entries,
      customerData,
      merchantData,
      merchantCustomerData,
      isCustomerPage,
      isMerchantPage,
      isMerchantCustomerPage,
    })
    return entries
  }, [customerData, merchantData, merchantCustomerData])

  const csvAction = () => {
    createCsvExport(filter.fulltext || '').then((data) => downloadAction(data, 'customers.csv'))
  }

  const [
    createLabel,
    pageHeadingCustomers,
    pageHeadingMerchants,
    csvExportLabel,
    idLabel,
    nameLabel,
    contactLabel,
    addressLabel,
    licensesLabel,
    searchLabel,
    contactNumberLabel,
    companyLabel,
  ] = useMemo(
    () => [
      t('actions.add'),
      t('objects.customers'),
      t('objects.merchants'),
      t('actions.csvExport'),
      t('contact.id'),
      t('contact.name'),
      t('contact.contact'),
      t('contact.address'),
      t('objects.licenses'),
      t('actions.search'),
      t('contact.number'),
      t('contact.company'),
    ],
    [t],
  )

  const columns: DataTableColumn[] = useMemo(
    () => [{ label: contactNumberLabel }, { label: companyLabel }, { label: addressLabel }, { label: licensesLabel }],
    [contactNumberLabel, nameLabel, companyLabel, addressLabel, licensesLabel],
  )

  const rows = useMemo(() => {
    if (isCustomerPage && customerData) {
      const contacts = customerData.customers.customers.map((c) => c.contact)
      const ids = customerData.customers.customers.map((c) => c.id)
      const nLicenses = customerData.customers.customers.map((c) => c.nLicenses)
      return contactsToRows(contacts, ids, nLicenses)
    } else if (isMerchantPage && merchantData) {
      const contacts = merchantData.merchants.merchants.map((m) => m.contact)
      const ids = merchantData.merchants.merchants.map((m) => m.id)
      const nLicenses = merchantData.merchants.merchants.map((m) => m.nLicenses)
      return contactsToRows(contacts, ids, nLicenses)
    } else if (isMerchantCustomerPage && merchantCustomerData) {
      const contacts = merchantCustomerData.merchantsCustomers.merchantsCustomers.map((m) => m?.contact)
      const ids = merchantCustomerData.merchantsCustomers.merchantsCustomers.map((m) => m?.id || '')
      const nLicenses = merchantCustomerData.merchantsCustomers.merchantsCustomers.map((m) => m?.nLicenses || 0)
      return contactsToRows(contacts, ids, nLicenses)
    }
    return []
  }, [isCustomerPage, merchantData, customerData, merchantCustomerData])

  const goToEditPage = useCallback(
    (id: string) => {
      let route = ''

      if (isCustomerPage) {
        route = generatePath(ROUTES.editCustomer, { id })
      }
      if (isMerchantPage) {
        route = generatePath(ROUTES.editMerchant, { id })
      }
      if (isMerchantCustomerPage) {
        const mc = merchantCustomerData?.merchantsCustomers.merchantsCustomers.find((mc) => mc?.id === id)
        if (mc?.type === 'MERCHANT') {
          route = generatePath(ROUTES.editMerchant, { id })
        }
        if (mc?.type === 'CUSTOMER') {
          route = generatePath(ROUTES.editCustomer, { id })
        }
      }

      history.push(route)
    },
    [history, isCustomerPage, isMerchantPage, isMerchantCustomerPage, merchantCustomerData],
  )

  const deletionConfirmed = useCallback(() => {
    if (isCustomerPage) {
      deleteCustomerMutation({ variables: { id: selectedID } }).then(() => refetchCustomers())
    }
    if (isMerchantPage) {
      deleteMerchantMutation({ variables: { id: selectedID } }).then(() => refetchMerchants())
    }
  }, [selectedID, deleteCustomerMutation, deleteMerchantMutation, isCustomerPage, refetchCustomers, refetchMerchants])

  const goToGrantLicensePage = useCallback(
    (id: string, action: string) => {
      console.log(
        'goToGrantLicensePage',
        { isCustomerPage, isMerchantPage, isMerchantCustomerPage, merchantCustomerData, id },
        merchantCustomerData?.merchantsCustomers.merchantsCustomers.find((mc) => mc?.id === id),
      )
      let route = ROUTES.grantLicense
      if (action === 'batchGrantLicense') {
        route = ROUTES.batchGrantLicense
      }
      if (isCustomerPage) {
        route = `${route}?customer=${id}`
      }
      if (isMerchantPage) {
        route = `${route}?merchant=${id}`
      }
      if (isMerchantCustomerPage) {
        const mc = merchantCustomerData?.merchantsCustomers.merchantsCustomers.find((mc) => mc?.id === id)
        if (mc?.type === 'MERCHANT') {
          route = `${route}?merchant=${id}`
        }
        if (mc?.type === 'CUSTOMER') {
          route = `${route}?customer=${id}`
        }
      }
      history.push(route)
    },
    [history, isCustomerPage, isMerchantPage, isMerchantCustomerPage, merchantCustomerData],
  )

  const goToBatchGrantLicensePage = useCallback(
    (id: string) => {
      const route = ROUTES.batchGrantLicense + (isCustomerPage ? `?customer=${id}` : `?merchant=${id}`)
      history.push(route)
    },
    [history],
  )

  const goToAddPage = useCallback(() => {
    isCustomerPage ? history.push(ROUTES.addCustomer) : history.push(ROUTES.addMerchant)
  }, [history, isCustomerPage])

  const pageHeadingLabel = () => {
    let label = contactLabel
    if (isCustomerPage) {
      label = pageHeadingCustomers
    }
    if (isMerchantPage) {
      label = pageHeadingMerchants
    }

    return `${label} (${nEntries})`
  }

  const pageHeading = useMemo(() => pageHeadingLabel(), [
    isCustomerPage,
    pageHeadingCustomers,
    pageHeadingMerchants,
    nEntries,
  ])

  const searchTermChanged = useCallback((term: string) => {
    setNewFilter((filter) => {
      return { ...filter, fulltext: term.trim() }
    })
    setCurrentPage(1)
  }, [])

  const tableButtonAction = useCallback(
    (id: string, action: DataItemAction) => {
      if (action === 'edit') {
        goToEditPage(id)
      } else if (action === 'delete') {
        setModalOpen(true)
        setSelectedID(id)
      } else if (action === 'batchGrantLicense') {
        goToGrantLicensePage(id, action)
      } else if (action === 'grantLicense') {
        goToGrantLicensePage(id, action)
      }
    },
    [goToEditPage, goToGrantLicensePage, goToBatchGrantLicensePage, isCustomerPage],
  )

  return (
    <>
      <StandardConfirmationModal
        handleClose={() => setModalOpen(false)}
        open={modalOpen}
        text={t('modals.standardText')}
        handleConfirm={deletionConfirmed}
      />
      <FixedActionBar
        labels={[csvExportLabel, createLabel]}
        actions={[csvAction, goToAddPage]}
        icons={[Description, Add]}
        buttonProps={[{}, { solid: true }]}
      />
      <PageHeading title={pageHeading} />
      <Grid container spacing={3} style={{ paddingTop: 20 }}>
        <Grid item xs={12} md={4}>
          <PaginationInfoLimitUpdater paginationParams={paginationData} setPaginationParams={setPaginationData} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Search label={searchLabel} changeFunction={searchTermChanged} />
        </Grid>
        <Grid item xs={2}>
          <CsButton solid={true} onClick={() => setFilter(newFilter)}>
            {t('actions.filter')}
          </CsButton>
        </Grid>
        <Grid item xs={12}>
          <DataTable
            columns={columns}
            rows={rows}
            onButtonClicked={tableButtonAction}
            allowedActions={{
              editable: true,
              licenseGrantable: true,
              batchGrantLicense: true,
              deletable: !isMerchantCustomerPage,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <LicenseServerPagination
            paginationParams={paginationData}
            setPaginationParams={setPaginationData}
            nTotal={nEntries}
          />
        </Grid>
      </Grid>
    </>
  )
}
