import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { NavigationDrawer } from './NavigationDrawer'

export type NavigationDrawerProps = {
  onDrawerAction: (open: boolean) => void
}

export const LicenseServerNavigation: React.FC<NavigationDrawerProps> = ({ onDrawerAction }) => {
  const history = useHistory()

  const linkClicked = useCallback(
    (link: string) => {
      if (link.startsWith('http')) {
        window.open(link, '_blank')
      } else {
        history.push(link)
      }
    },
    [history],
  )

  return <NavigationDrawer onDrawerAction={onDrawerAction} onLinkClicked={linkClicked} />
}
