import React, { FC, ReactElement, useCallback, useMemo, useState } from 'react'
import { PaginationParams } from '../../../../api/models'
import { Pagination } from '@material-ui/lab'
import { useRecoilState } from 'recoil'
import { paginationState } from '../../../helpers/recoil'

type LicenseServerPaginationProps = {
  paginationParams: PaginationParams
  setPaginationParams: React.Dispatch<React.SetStateAction<PaginationParams>>
  nTotal: number
}

export const LicenseServerPagination: FC<LicenseServerPaginationProps> = ({
  paginationParams,
  setPaginationParams,
  nTotal,
}): ReactElement => {
  const [currentPage, setCurrentPage] = useRecoilState(paginationState)

  const nPages = useMemo(() => {
    return Math.ceil(nTotal / (paginationParams.limit || 100))
  }, [paginationParams, nTotal])

  const updatePage = useCallback(
    (event: unknown, page: number) => {
      setPaginationParams((paginationParams) => {
        return { ...paginationParams, Offset: (page - 1) * (paginationParams.limit || 100) }
      })
      setCurrentPage(page)
    },
    [setPaginationParams],
  )

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 50,
        paddingBottom: 150,
      }}
    >
      <Pagination count={nPages} page={currentPage} onChange={updatePage} />
    </div>
  )
}
