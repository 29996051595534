import React, { FC, ReactElement, useMemo, useState } from 'react'
import { Button, Container, Grid, TextField, Typography } from '@material-ui/core'
import { FixedActionBar } from '@csinstruments/cs-react-theme'
import { ArrowRight } from '@material-ui/icons'
import { useTranslation } from '../../../hooks/useTranslation'
import { PageHeading } from '../../partials/PageHeading'
import { useActivateLicenseServerV1Mutation } from '../../../../api/models'
import { ResultAlert } from '../../partials/ResultAlert'
import { ClipboardCopy } from '../../partials/ClipboardCopy'
import AddIcon from '@material-ui/icons/Add'

export const ActivateLicenseServer: FC = ({}): ReactElement => {
  const { t } = useTranslation()
  const [cipher, setCipher] = useState('')
  const [cipherResponse, setCipherResponse] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertSeverity, setAlertSeverity] = useState<'error' | 'warning' | 'success' | 'info'>('info')
  const [activateLicenseV1] = useActivateLicenseServerV1Mutation()
  const handleActivate = () => {
    activateLicenseV1({
      variables: { cipher, lang: 'de' },
    })
      .then((e) => {
        setAlertMessage(e.data?.activateLicenseServerV1.message || '')
        setAlertSeverity(e.data?.activateLicenseServerV1.success ? 'success' : 'error')
        setShowAlert(true)
        if (e.data?.activateLicenseServerV1.success) {
          setCipherResponse(e.data?.activateLicenseServerV1.cipher || '')
        }
      })
      .catch(() => {
        setAlertMessage('Error while communicate with backend')
        setAlertSeverity('error')
        setShowAlert(true)
      })
  }

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCipher(e.target.value)
  }

  const [licenseLabel, keyLabel, activateLicenseLabel, offlineActivationText] = useMemo(() => {
    return [
      t('actions.activateLicense'),
      t('objects.message'),
      t('actions.activateLicense'),
      t('licenses.offlineActivationText'),
    ]
  }, [t])

  return (
    <Container style={{ padding: 50 }}>
      <ResultAlert
        alertText={alertMessage}
        showAlert={showAlert}
        modifyShowAlert={setShowAlert}
        severity={alertSeverity}
      />
      <PageHeading title={licenseLabel} />
      <Grid container spacing={3} style={{ paddingTop: 20, paddingBottom: 50 }}>
        <Typography variant={'body1'} style={{ marginBottom: 15 }}>
          {offlineActivationText}
        </Typography>
        <TextField
          multiline
          rows={10}
          label={keyLabel}
          variant="outlined"
          style={{ width: '100%' }}
          onChange={(e) => handleTextFieldChange(e)}
          disabled={alertSeverity === 'success'}
        />
        <Button
          style={{ marginTop: '15px' }}
          variant={'contained'}
          startIcon={<AddIcon />}
          onClick={handleActivate}
          disabled={!!cipherResponse}
        >
          {activateLicenseLabel}
        </Button>
      </Grid>
      {cipherResponse && <ClipboardCopy copyText={cipherResponse} />}
    </Container>
  )
}
