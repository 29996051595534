import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react'
import { DashboardTile } from './DashboardTile'
import { LocalOffer, People, Pool, Storefront } from '@material-ui/icons'
import { Grid } from '@material-ui/core'
import { ROUTES } from '../../../helpers/routes'
import { useTranslation } from '../../../hooks/useTranslation'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooksOutlined'
import { useDashboardInfoQuery } from '../../../../api/models'
import { toLocaleDateTime } from '../../../helpers/dateTime'
import { getTokens, hasUserRoles } from '../../../helpers/keycloakService'
import { ProgressBar } from '../../partials/ProgressBar/ProgressBar'

type DashboardInfo = {
  nLicensePools: number
  nLicenses: number
  nMerchants: number
  nCustomers: number
  nLicenseTypes: number
  nUsers: number

  poolsSubtitle: string
  licensesSubtitle: string
  merchantsSubtitle: string
  customersSubtitle: string
  typesSubtitle: string
  usersSubtitle: string
}

export const Dashboard: FC = ({}): ReactElement => {
  const { t } = useTranslation()
  const { data, loading } = useDashboardInfoQuery()
  const [info, setInfo] = useState<DashboardInfo>({
    nLicensePools: 0,
    nLicenses: 0,
    nMerchants: 0,
    nCustomers: 0,
    nUsers: 0,
    nLicenseTypes: 0,
    poolsSubtitle: '',
    licensesSubtitle: '',
    merchantsSubtitle: '',
    usersSubtitle: '',
    customersSubtitle: '',
    typesSubtitle: '',
  })

  const [token] = getTokens()
  const masterUser = useMemo(() => {
    return hasUserRoles(token).master
  }, [token])

  const [
    poolsTitle,
    licensesTitle,
    merchantsTitle,
    customersTitle,
    typesTitle,
    newestLabel,
    totalUsersLabel,
  ] = useMemo(
    () => [
      t('objects.licensePools'),
      t('objects.licenses'),
      t('objects.merchants'),
      t('objects.customers'),
      t('objects.licenseTypes'),
      t('objects.newest'),
      t('objects.totalUsers'),
    ],
    [t],
  )

  useEffect(() => {
    if (!data || !data.dashboardInfo) {
      return
    }
    const {
      nLicensePools,
      nLicenses,
      nMerchants,
      nCustomers,
      nUsers,
      nLicenseTypes,
      lastCustomerName,
      lastLicenseCreationDate,
      lastLicensePoolCreationDate,
      lastMerchantCreationDate,
      lastLicenseTypeName,
    } = data.dashboardInfo

    const poolsSubtitle = `${newestLabel}: ${toLocaleDateTime(lastLicensePoolCreationDate) || '-'}`
    const licensesSubtitle = `${newestLabel}: ${toLocaleDateTime(lastLicenseCreationDate) || '-'}`
    const merchantsSubtitle = `${newestLabel}: ${toLocaleDateTime(lastMerchantCreationDate)}`
    const usersSubtitle = `${totalUsersLabel}: ${nUsers}`
    const customersSubtitle = `${newestLabel}: ${lastCustomerName || '-'}`
    const typesSubtitle = `${newestLabel}: ${lastLicenseTypeName || '-'}`

    setInfo({
      nLicensePools,
      nLicenses,
      nMerchants,
      nUsers,
      nCustomers,
      nLicenseTypes,
      poolsSubtitle,
      licensesSubtitle,
      merchantsSubtitle,
      customersSubtitle,
      typesSubtitle,
      usersSubtitle,
    })
  }, [data, newestLabel, totalUsersLabel])

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ProgressBar show={loading} />
        </Grid>
        <DashboardTile
          title={poolsTitle}
          subtitle={info.poolsSubtitle}
          icon={Pool}
          number={info.nLicensePools}
          link={ROUTES.licensePools}
        />
        {masterUser && (
          <DashboardTile
            title={t('objects.users')}
            subtitle={info.usersSubtitle}
            icon={People}
            number={info.nUsers}
            link={ROUTES.users}
          />
        )}
        {!masterUser && (
          <>
            <DashboardTile
              title={licensesTitle}
              subtitle={info.licensesSubtitle}
              icon={LibraryBooksIcon}
              number={info.nLicenses}
              link={ROUTES.licenses}
            />
            <DashboardTile
              title={merchantsTitle}
              subtitle={info.merchantsSubtitle}
              icon={Storefront}
              number={info.nMerchants}
              link={ROUTES.merchants}
            />
            <DashboardTile
              title={customersTitle}
              subtitle={info.customersSubtitle}
              icon={People}
              number={info.nCustomers}
              link={ROUTES.customers}
            />
          </>
        )}
        <DashboardTile
          title={typesTitle}
          subtitle={info.typesSubtitle}
          icon={LocalOffer}
          number={info.nLicenseTypes}
          link={ROUTES.licenseTypes}
        />
      </Grid>
    </>
  )
}
