import { LinearProgress } from '@material-ui/core'
import React, { FC, ReactElement } from 'react'
import styles from './ProgressBar.module.css'

type ProgressBarProps = {
  show: boolean
}

export const ProgressBar: FC<ProgressBarProps> = ({ show }): ReactElement => {
  return (
    <div className={styles['progress-bar']}>
      <LinearProgress color="secondary" style={show ? {} : { display: 'none' }} />
    </div>
  )
}
