import React, { FC, ReactElement, useMemo, useState } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { PaginationParams, useGroupsQuery, useUsersQuery } from '../../../../api/models'
import { PageHeading } from '../../partials/PageHeading'
import { Box, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core'
import { PaginationInfoLimitUpdater } from '../../partials/Pagination/PaginationInfoLimitUpdater'
import { LicenseServerPagination } from '../../partials/Pagination/LicenseServerPagination'
import { DataTable } from '../../partials/DataTable/DataTable'
import { DataTableColumn, DataTableRow } from '../../partials/DataTable/DataTableTypes'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes'
import { ProgressBar } from '../../partials/ProgressBar/ProgressBar'

type UserParams = {
  groupID: string
}

export const Users: FC = ({}): ReactElement => {
  const { t } = useTranslation()
  const history = useHistory()
  const { groupID } = useParams<UserParams>()
  const [paginationData, setPaginationData] = useState<PaginationParams>({ limit: 100, Offset: 0 })
  const [selectedGroupID, setSelectedGroupID] = useState(groupID || '')
  const { data, loading } = useUsersQuery({
    variables: { paginationParams: paginationData, groupID: selectedGroupID || null },
  })
  const { data: groupsData } = useGroupsQuery({
    variables: {},
  })

  const columns: DataTableColumn[] = useMemo(() => [{ label: t('objects.id') }, { label: t('objects.email') }], [t])

  const rows: DataTableRow[] = useMemo(() => {
    if (!data?.users) {
      return []
    }

    return data.users.users.map((u) => {
      return { id: u.id, values: [u.id, u.email] }
    })
  }, [data?.users])

  return (
    <>
      <PageHeading title={t('objects.users')} />
      <Grid container spacing={3} style={{ paddingTop: 20 }}>
        <Grid item xs={12}>
          <ProgressBar show={loading} />
        </Grid>

        <Grid item xs={12}>
          <Box mb={5}>
            <Typography variant="body2">{t('users.keycloakHint')}</Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <PaginationInfoLimitUpdater paginationParams={paginationData} setPaginationParams={setPaginationData} />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputLabel id="pagination-updater">{t('objects.groups')}</InputLabel>
          <Select fullWidth value={selectedGroupID} onChange={(val) => setSelectedGroupID(val.target.value as string)}>
            <MenuItem value={''}>&nbsp; </MenuItem>
            {groupsData?.groups
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((g) => (
                <MenuItem key={g.id} value={g.id}>
                  {g.name}
                </MenuItem>
              ))}
          </Select>
        </Grid>

        <Grid item xs={12}>
          <DataTable
            columns={columns}
            onButtonClicked={(id) => {
              history.push(generatePath(ROUTES.addLicensePoolForUser, { userID: id }))
            }}
            rows={rows}
            allowedActions={{ sortable: true, licenseGrantable: true }}
          />
        </Grid>

        <Grid item xs={12}>
          <LicenseServerPagination
            paginationParams={paginationData}
            setPaginationParams={setPaginationData}
            nTotal={data?.users.total || 0}
          />
        </Grid>
      </Grid>
    </>
  )
}
