import { paletteColors } from '@csinstruments/cs-react-theme'

export const completeContainer = {
  overflowX: 'hidden' as const,
  backgroundColor: paletteColors().primary,
}

export const outerPageContainer = {
  backgroundColor: '#fff',
}

export const innerPageContainer = {
  backgroundColor: '#fff',
  borderTopLeftRadius: 50,
}

export const mainPageContainer = {
  width: '90%',
  margin: 'auto',
  maxHeight: '100%',
  paddingBottom: '20px',
  paddingTop: '40px',
  flexGrow: 1,
} as React.CSSProperties

export const cardDefault = {
  display: 'flex',
  flexDirection: 'column' as const,
  height: '175px',
  justifyContent: 'space-between',
  padding: 15,
  cursor: 'pointer',
}
