import React, { ReactElement, useState } from 'react'
import {
  GetAllLicenseInstancesQueryVariables,
  LicenseInstance,
  PaginationParams,
  useGetAllLicenseInstancesQuery,
} from '../../../../api/models'
import { PageHeading } from '../../partials/PageHeading'
import { LicenseInstancesTable } from './LicenseInstancesTable'
import { Grid } from '@material-ui/core'
import { useTranslation } from '../../../hooks/useTranslation'
import { PaginationInfoLimitUpdater } from '../../partials/Pagination/PaginationInfoLimitUpdater'
import { LicenseFilterUpdater } from './LicenseFilterUpdater'
import { LicenseServerPagination } from '../../partials/Pagination/LicenseServerPagination'

export const ListLicenseInstances = (): ReactElement => {
  const { t } = useTranslation()
  const [paginationData, setPaginationData] = useState<PaginationParams>({ limit: 10, Offset: 0 })
  const [filter, setFilter] = useState<GetAllLicenseInstancesQueryVariables>({})
  const { data: instances, refetch: refetchInstances } = useGetAllLicenseInstancesQuery({
    variables: {
      fulltext: '',
      paginationInfo: paginationData,
      ...filter,
    },
  })

  const allLicenseInstances = (instances?.getAllLicenseInstances.instances as unknown) as LicenseInstance[]

  return (
    <>
      <PageHeading title={t('objects.activations')} />
      <Grid container spacing={3} style={{ paddingTop: 20 }}>
        <Grid item xs={12} md={4}>
          <PaginationInfoLimitUpdater paginationParams={paginationData} setPaginationParams={setPaginationData} />
        </Grid>
        <Grid item xs={12} md={8}>
          <LicenseFilterUpdater setFilter={setFilter} />
        </Grid>
        {instances && (
          <LicenseInstancesTable
            showHeadline={false}
            licenseInstances={allLicenseInstances}
            refetch={refetchInstances}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <LicenseServerPagination
          paginationParams={paginationData}
          setPaginationParams={setPaginationData}
          nTotal={instances?.getAllLicenseInstances?.total || 0}
        />
      </Grid>
    </>
  )
}
