export type ConversionResult = {
  result?: string
  error: boolean
}

export const base64ToText = (base64: string): ConversionResult => {
  const parts = base64.split(';base64,')
  if (parts.length !== 2) {
    return { error: true }
  }

  let result = ''
  try {
    result = atob(parts[1])
  } catch (e) {
    return { error: true }
  }
  return { result, error: false }
}
