export const poolToString = (typeKey: string, inUse: number, available?: number | null): string => {
  return `${typeKey} [${inUse}/${available ?? '∞'}]`
}

export const contactToString = (firstName?: string | null, lastName?: string | null): string => {
  return (firstName ? `${firstName}` : '') + (lastName ? `${firstName ? ' ' + lastName : lastName}` : '')
}

export const valueOrNullToString = (s?: string | null | number): string => {
  return s ? `${s}` : ''
}

export const numberOrInfiniteToString = (n?: number | null): string => {
  return n ? `${n}` : '∞'
}
