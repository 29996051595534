import React, { FC, ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { JournalFilter, useActions_JournalQuery } from '../../../../api/models'
import { toLocaleDateTime } from '../../../helpers/dateTime'
import { Grid, Typography } from '@material-ui/core'
import MaterialTable from 'material-table'

type Props = { filter: JournalFilter; showTitle?: boolean }

export const JournalTable: FC<Props> = ({ filter, showTitle }): ReactElement => {
  const { t } = useTranslation()
  const { data } = useActions_JournalQuery({ variables: { filter } })

  // "any": type not known
  // eslint-disable-next-line
  const changeDataToString = useCallback(
    (changes: any): React.ReactElement => {
      if (!changes) return <></>
      const keys = Object.keys(changes)

      return (
        <>
          {keys
            .filter((k) => !!changes[k])
            .map((k, i) => (
              <div style={{ whiteSpace: 'nowrap' }} key={k}>
                {i + 1}. {t(`journal.${k}`)}: <span style={{ color: 'green' }}>{changes[k]}</span>
              </div>
            ))}
        </>
      )
    },
    [t],
  )

  const rows = useMemo(
    () =>
      (data?.actions_journal || []).map((rd) => {
        return {
          id: rd.id as string,
          action_type: rd.action_type as string,
          action_user: rd.action_user as string,
          description: rd.merchant_name
            ? `Händler: ${rd.merchant_name}`
            : rd.customer_name
            ? `Kunde: ${rd.customer_name}`
            : rd.license_name
            ? `Lizenz: ${rd.license_name}`
            : rd.contact_name
            ? `Kontakt: ${rd.contact_name}`
            : rd.license_pool_name
            ? `Lizenz-Pool: ${rd.license_pool_name}`
            : rd.license_type_name
            ? `Lizenz-Typ: ${rd.license_type_name}`
            : '',
          time: toLocaleDateTime(rd.date),
          changes: <div>{changeDataToString(JSON.parse(rd.difference || '{}'))}</div>,
        }
      }),
    [data, changeDataToString],
  )

  return (
    <Grid item xs={12} style={{ paddingBottom: 150 }}>
      {showTitle && (
        <Typography color="secondary" style={{ paddingTop: 20 }} variant={'h5'}>
          {t('objects.journal')}
        </Typography>
      )}
      <MaterialTable
        components={{
          // eslint-disable-next-line react/display-name
          Container: (props) => <div>{props.children}</div>,
        }}
        options={{ draggable: false, paging: false, search: false, showTitle: false, sorting: false }}
        columns={[
          { title: 'ID', field: 'id' },
          { title: 'Typ', field: 'action_type' },
          { title: 'Datensatz', field: 'description' },
          { title: 'Datum', field: 'time' },
          { title: 'Änderungen', field: 'changes' },
          { title: 'Benutzer', field: 'action_user' },
        ]}
        data={rows}
      />
    </Grid>
  )
}
