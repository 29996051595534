interface Routes {
  home: string
  grantLicense: string
  grantLicenseForPool: string
  batchGrantLicense: string
  licenses: string
  licensePools: string
  licenseTypes: string
  licenseInstances: string
  addMerchant: string
  merchants: string
  customers: string
  merchantsCustomers: string
  addCustomer: string
  journal: string
  addLicensePool: string
  addLicenseType: string
  editLicenseType: string
  editCustomer: string
  editMerchant: string
  editLicense: string
  activateLicense: string
  activateLicenseLicenseServerV1: string
  deactivateLicenseLicenseServerV1: string
  activateLicenseV1: string
  licenseActivations: string
  users: string
  groupUsers: string
  editLicensePool: string
  addLicensePoolForGroup: string
  addLicensePoolForUser: string
  groups: string
}

export const getRouteDefinitions = (): Routes => {
  return {
    home: '/home',
    grantLicense: '/grant-license',
    grantLicenseForPool: '/grant-license/pool/:poolID',
    batchGrantLicense: '/batch-grant-license',
    editLicense: '/grant-license/:id',
    licenses: '/licenses',
    licensePools: '/license-pools',
    licenseTypes: '/license-types',
    licenseInstances: '/license-instances',
    addMerchant: '/add-merchant',
    merchants: '/merchants',
    merchantsCustomers: '/merchants-customers',
    customers: '/customers',
    addCustomer: '/add-customer',
    journal: '/journal',
    addLicenseType: '/add-license-type',
    addLicensePool: '/add-license-pool',
    addLicensePoolForGroup: '/add-license-pool/group/:groupID',
    addLicensePoolForUser: '/add-license-pool/user/:userID',
    editCustomer: '/edit-customer/:id',
    editLicenseType: '/edit-license-type/:id',
    editMerchant: '/edit-merchant/:id',
    editLicensePool: '/edit-license-pool/:id',
    activateLicense: '/activate-license',
    activateLicenseV1: '/activate-license-v1',
    activateLicenseLicenseServerV1: '/activate-license-server',
    deactivateLicenseLicenseServerV1: '/deactivate-license-server',
    licenseActivations: '/license-activations',
    users: '/users',
    groupUsers: '/users/:groupID',
    groups: '/groups',
  }
}

export const ROUTES = getRouteDefinitions()
