import React, { useState } from 'react'
import { Button, Grid, TextField, Typography } from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import GetAppIcon from '@material-ui/icons/GetApp'
import { usePushDownloadFile } from '../../hooks/usePushDownloadFile'
import { useTranslation } from '../../hooks/useTranslation'

export type ClipboardCopyProps = {
  copyText: string
}

export const ClipboardCopy = ({ copyText }: ClipboardCopyProps) => {
  const { t } = useTranslation()
  const [isCopied, setIsCopied] = useState(false)
  const { downloadAction } = usePushDownloadFile()
  // This is the function we wrote earlier
  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  const handleDownloadAction = () => {
    downloadAction(copyText, 'activationCipher.txt')
  }

  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true)
        setTimeout(() => {
          setIsCopied(false)
        }, 1500)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <Grid container spacing={3} style={{ paddingTop: 20, paddingBottom: 50 }}>
      <Typography variant={'body1'} style={{ marginBottom: 15 }}>
        {t('licenses.offlineActivationResponseText')}
      </Typography>
      <TextField
        variant="outlined"
        label={'Cipher'}
        multiline
        value={copyText}
        rows={8}
        disabled
        style={{ width: '100%' }}
      />

      <Button
        variant={'contained'}
        startIcon={<GetAppIcon />}
        onClick={handleDownloadAction}
        style={{ marginTop: '15px', marginRight: '15px' }}
      >
        {t('licenses.downloadOfflineFile')}
      </Button>
      <Button variant={'outlined'} startIcon={<FileCopyIcon />} onClick={handleCopyClick} style={{ marginTop: '15px' }}>
        {isCopied ? 'Copied!' : 'Copy to Clipboard'}
      </Button>
    </Grid>
  )
}
