import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { PageHeading } from '../../partials/PageHeading'
import { Grid } from '@material-ui/core'
import {
  LicenseFilterUpdater as LicenseFilter,
  LicensesQuery,
  PaginationParams,
  useDeleteLicenseMutation,
  useLicensesQuery,
} from '../../../../api/models'
import { PaginationInfoLimitUpdater } from '../../partials/Pagination/PaginationInfoLimitUpdater'
import { LicenseServerPagination } from '../../partials/Pagination/LicenseServerPagination'
import { DataItemAction, DataTableColumn, DataTableRow } from '../../partials/DataTable/DataTableTypes'
import { DataTable } from '../../partials/DataTable/DataTable'
import { FixedActionBar } from '@csinstruments/cs-react-theme'
import { Add } from '@material-ui/icons'
import { useHistory, generatePath } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes'
import {
  contactToString,
  numberOrInfiniteToString,
  poolToString,
  valueOrNullToString,
} from '../../../helpers/stringHelpers'
import { toLocaleDateTime } from '../../../helpers/dateTime'
import { StandardConfirmationModal } from '../../partials/StandardConfirmationModal/StandardConfirmationModal'
import { LicenseFilterUpdater } from './LicenseFilterUpdater'

const licensesToRows = (licenses: LicensesQuery | undefined): DataTableRow[] => {
  if (!licenses) {
    return []
  }
  return licenses.licenses.licenses.map((l) => {
    const customerNumber = l?.merchant?.contact?.customerid || l?.customer?.contact?.customerid
    return {
      id: l.id,
      values: [
        l.license_key,
        l.pools.map((p) => poolToString(p.license_type_key || '', p.n_in_use, p.pool_size)).join(', '),
        contactToString(l.merchant?.contact?.company),
        contactToString(l.customer?.contact?.company),
        valueOrNullToString(customerNumber),
        l.orderNumber,
        numberOrInfiniteToString(l.activation_maximum),
        valueOrNullToString(l.nActivations) || '0',
        toLocaleDateTime(l.date_updated),
        l.changed_by,
      ],
    }
  })
}

export const ListLicenses = (): ReactElement => {
  const { t } = useTranslation()
  const history = useHistory()
  const [paginationData, setPaginationData] = useState<PaginationParams>({ limit: 10, Offset: 0 })
  const [filter, setFilter] = useState<LicenseFilter>({})
  const [selectedID, setSelectedID] = useState('')

  const [modalOpen, setModalOpen] = useState(false)
  const [deleteLicenseMutation] = useDeleteLicenseMutation()
  const { data, refetch } = useLicensesQuery({ variables: { paginationInfo: paginationData, filter } })

  const [
    licensesLabel,
    idLabel,
    keyLabel,
    licencePoolsLabel,
    merchantsLabel,
    customersLabel,
    noteLabel,
    maximumActivationsLabel,
    activationsLabel,
    createdAtLabel,
    updatedAtLabel,
    updatedByLabel,
    grantLicenseLabel,
    orderNumberLabel,
    contactNumberLabel,
  ] = useMemo(() => {
    return [
      t('objects.licenses'),
      t('objects.id'),
      t('licenses.licenseKey'),
      t('objects.licensePools'),
      t('objects.merchants'),
      t('objects.customers'),
      t('objects.note'),
      t('licenses.maximumActivations'),
      t('licenses.activations'),
      t('licenses.createdAt'),
      t('licenses.updatedAt'),
      t('licenses.updatedBy'),
      t('licenses.grantLicense'),
      t('objects.orderNumber'),
      t('contact.number'),
    ]
  }, [t])

  const rows = useMemo(() => licensesToRows(data), [data])

  const columns: DataTableColumn[] = useMemo(
    () => [
      { label: keyLabel },
      { label: licencePoolsLabel },
      { label: merchantsLabel },
      { label: customersLabel },
      { label: contactNumberLabel },
      { label: orderNumberLabel },
      { label: maximumActivationsLabel },
      { label: activationsLabel },
      { label: updatedAtLabel },
      { label: updatedByLabel },
    ],
    [
      keyLabel,
      licencePoolsLabel,
      merchantsLabel,
      customersLabel,
      contactNumberLabel,
      orderNumberLabel,
      maximumActivationsLabel,
      activationsLabel,
      updatedAtLabel,
      updatedByLabel,
    ],
  )

  const grantLicenseAction = useCallback(() => {
    return history.push(ROUTES.grantLicense)
  }, [history])

  const deletionConfirmed = useCallback(() => {
    deleteLicenseMutation({ variables: { id: selectedID } }).then(() => refetch())
  }, [selectedID, deleteLicenseMutation, refetch])

  const buttonAction = useCallback(
    (id: string, action: DataItemAction) => {
      if (action === 'delete') {
        setModalOpen(true)
        setSelectedID(id)
      } else {
        const path = generatePath(ROUTES.editLicense, { id })
        history.push(path)
      }
    },
    [history],
  )

  return (
    <>
      <PageHeading title={licensesLabel} />
      <StandardConfirmationModal
        handleClose={() => setModalOpen(false)}
        open={modalOpen}
        text={t('modals.standardText')}
        handleConfirm={deletionConfirmed}
      />
      <FixedActionBar
        labels={[grantLicenseLabel]}
        actions={[grantLicenseAction]}
        icons={[Add]}
        buttonProps={[{ solid: true }]}
      />
      <Grid container spacing={3} style={{ paddingTop: 20 }}>
        <Grid item xs={12} md={4}>
          <PaginationInfoLimitUpdater paginationParams={paginationData} setPaginationParams={setPaginationData} />
        </Grid>
        <Grid item xs={12} md={8}>
          <LicenseFilterUpdater setFilter={setFilter} />
        </Grid>
        <Grid item xs={12}>
          <DataTable
            columns={columns}
            onButtonClicked={buttonAction}
            rows={rows}
            allowedActions={{ editable: true, deletable: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <LicenseServerPagination
            paginationParams={paginationData}
            setPaginationParams={setPaginationData}
            nTotal={data?.licenses.total || 0}
          />
        </Grid>
      </Grid>
    </>
  )
}
