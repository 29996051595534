import { useCallback } from 'react'

type PushDownloadResult = {
  downloadAction: (content: BlobPart, fileName: string) => void
}

export const usePushDownloadFile = (): PushDownloadResult => {
  const downloadAction = useCallback((content: BlobPart, fileName: string): void => {
    const element = document.createElement('a')
    const file = new Blob([content])
    element.href = URL.createObjectURL(file)
    element.download = fileName
    document.body.appendChild(element)
    element.click()
  }, [])

  return { downloadAction }
}
