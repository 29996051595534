import { environment } from './environment'
import { getTokens } from './keycloakService'
import axios from 'axios'

export const createCsvExport = async (fulltext: string): Promise<string> => {
  const url = fulltext ? `${environment.CSV_EXPORT_URL}-filter/${fulltext}` : environment.CSV_EXPORT_URL
  const [token] = getTokens()
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { fulltext },
  }

  const result = await axios.get(url, config)

  return result.data
}
