import React, { FC, ReactElement, useCallback, useState } from 'react'
import { NavigationEntrySetProps } from './types'
import ListItem from '@material-ui/core/ListItem'
import { listItem, listItemOpen, useStyles } from './NavigationDrawerStyles'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import ListItemText from '@material-ui/core/ListItemText'
import { Divider, Typography } from '@material-ui/core'
import { useTranslation } from '../../../hooks/useTranslation'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { KeyboardArrowDown } from '@material-ui/icons'
import { getNavigationEntry } from './getNavigationEntry'

export const NavigationEntrySet: FC<NavigationEntrySetProps> = (props): ReactElement => {
  const { drawerOpen, text, entries, activeLink, onLinkClicked } = props
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const { t } = useTranslation()

  const clicked = useCallback(() => {
    setOpen((open) => !open)
  }, [])

  return (
    <>
      <Divider />
      <ListItem selected={false} button style={drawerOpen ? listItemOpen : listItem} onClick={clicked}>
        <ListItemIcon>
          {!drawerOpen && (
            <Tooltip placement={'right-start'} title={t(text) as string}>
              <props.icon className={classes.drawerText} />
            </Tooltip>
          )}
          {drawerOpen && <props.icon className={classes.drawerText} />}
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={drawerOpen ? <Typography variant={'subtitle1'}>{'\u00A0' + t(text)}</Typography> : undefined}
        />
        <ListItemIcon>
          {open && <KeyboardArrowDown className={classes.drawerText} style={{ marginLeft: 'auto' }} />}
          {!open && <ChevronLeftIcon className={classes.drawerText} style={{ marginLeft: 'auto' }} />}
        </ListItemIcon>
      </ListItem>
      {open &&
        entries.map((e) => {
          return (
            <div key={e.link}>
              {getNavigationEntry(classes, activeLink === e.link, e, onLinkClicked, drawerOpen, t)}
            </div>
          )
        })}
      <Divider />
    </>
  )
}
