import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmationModal } from '@csinstruments/cs-react-theme/dist/components/ConfirmationModal/ConfirmationModal'

export type StandardConfirmationModalProps = {
  text?: string
  handleConfirm: () => void
  open: boolean
  handleClose: () => void
}

type ConfirmationModalTexts = {
  textMain: string
  textCancel: string
  textConfirm: string
  textHeading: string
}

export const StandardConfirmationModal: React.FC<StandardConfirmationModalProps> = ({
  handleConfirm,
  open,
  handleClose,
  text,
}) => {
  const { t } = useTranslation('common')

  const texts: ConfirmationModalTexts = useMemo(() => {
    return {
      textMain: text ?? t('modals.confirmationText'),
      textCancel: t('modals.cancel'),
      textConfirm: t('modals.confirm'),
      textHeading: t('modals.confirmationHeading'),
    }
  }, [text, t])

  return (
    <ConfirmationModal
      handleConfirm={handleConfirm}
      open={open}
      handleClose={handleClose}
      textMain={texts.textMain}
      textCancel={texts.textCancel}
      textConfirm={texts.textConfirm}
      textHeading={texts.textHeading}
    />
  )
}
