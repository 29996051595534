import React, { FC, ReactElement, useMemo, useState } from 'react'
import { Button, Container, Grid, TextField, Typography } from '@material-ui/core'
import { FixedActionBar } from '@csinstruments/cs-react-theme'
import { ArrowRight } from '@material-ui/icons'
import { useTranslation } from '../../../hooks/useTranslation'
import { PageHeading } from '../../partials/PageHeading'
import { useDeactivateLicenseServerV1Mutation } from '../../../../api/models'
import { ResultAlert } from '../../partials/ResultAlert'
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation'

export const DeactivateLicenseServer: FC = ({}): ReactElement => {
  const { t } = useTranslation()
  const [licenseInstanceID, setLicenseInstanceID] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertSeverity, setAlertSeverity] = useState<'error' | 'warning' | 'success' | 'info'>('info')
  const [deactivateLicenseV1] = useDeactivateLicenseServerV1Mutation()
  const handleDeactivation = () => {
    deactivateLicenseV1({
      variables: { licenseInstanceID, lang: 'de' },
    })
      .then((e) => {
        setAlertMessage(e.data?.deactivateLicenseServerV1.message || '')
        setAlertSeverity(e.data?.deactivateLicenseServerV1.success ? 'success' : 'error')
        setShowAlert(true)
      })
      .catch(() => {
        setAlertMessage('Error while communicate with backend')
        setAlertSeverity('error')
        setShowAlert(true)
      })
  }

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setLicenseInstanceID(e.target.value)
  }

  const [licenseLabel, keyLabel, activateLicenseLabel] = useMemo(() => {
    return [t('actions.deactivateLicense'), t('objects.message'), t('actions.deactivateLicense')]
  }, [t])

  return (
    <Container style={{ padding: 50 }}>
      <ResultAlert
        alertText={alertMessage}
        showAlert={showAlert}
        modifyShowAlert={setShowAlert}
        severity={alertSeverity}
      />

      <PageHeading title={licenseLabel} />
      <Grid container spacing={3} style={{ paddingTop: 20, paddingBottom: 50 }}>
        <Typography variant={'body1'} style={{ marginBottom: 15 }}>
          {t('licenses.offlineDeactivationText')}
        </Typography>
        <TextField
          multiline
          rows={10}
          label={keyLabel}
          variant="outlined"
          style={{ width: '100%' }}
          onChange={(e) => handleTextFieldChange(e)}
          disabled={alertSeverity === 'success'}
        />
        <Button
          variant={'contained'}
          onClick={handleDeactivation}
          startIcon={<CancelPresentationIcon />}
          disabled={!!alertMessage}
          style={{ marginTop: '15px' }}
        >
          {activateLicenseLabel}
        </Button>
      </Grid>
    </Container>
  )
}
