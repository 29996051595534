import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import SearchIcon from '@material-ui/icons/SearchOutlined'
import { Cancel } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import { useCookies } from 'react-cookie'

export type SearchProps = {
  label: string
  changeFunction: (search: string) => void
}

export const Search: React.FC<SearchProps> = ({ label, changeFunction }) => {
  const [cookies, setCookie] = useCookies(['filter'])
  const [textValue, setTextValue] = useState<string>(cookies?.filter?.fulltext || '')
  const textChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
    changeFunction(e.target.value)
    setTextValue(e.target.value)
  }

  const handleCancel = (): void => {
    changeFunction('')
    setTextValue('')
  }

  return (
    <>
      <form noValidate autoComplete="off">
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleCancel} edge="end">
                  <Cancel />
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
          fullWidth
          id="standard-basic"
          label={label}
          value={textValue}
          onChange={textChanged}
        />
      </form>
    </>
  )
}

export default Search
