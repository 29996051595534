import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { FixedActionBar } from '@csinstruments/cs-react-theme'
import { Save } from '@material-ui/icons'
import { PageHeading } from '../../partials/PageHeading'
import { Grid } from '@material-ui/core'
import { Text } from '../../partials/Inputs/Text'
import { useCreateLicenseTypeMutation, useLicenseTypeQuery, useUpdateLicenseTypeMutation } from '../../../../api/models'
import { ResultAlert } from '../../partials/ResultAlert'
import { ROUTES } from '../../../helpers/routes'
import { useHistory, useParams } from 'react-router-dom'
import { JournalTable } from '../Journal/JournalTable'
import { ProgressBar } from '../../partials/ProgressBar/ProgressBar'

type AddLicenseFormValues = {
  typeKey: string
  maximumActivations: string
  description: string
}

const initialFormValues = {
  typeKey: '',
  maximumActivations: '',
  description: '',
}

type CreateOrUpdateTypeParams = {
  id?: string
}

export const CreateOrUpdateLicenseType: FC = ({}): ReactElement => {
  const { t } = useTranslation()
  const [formValues, setFormValues] = useState<AddLicenseFormValues>(initialFormValues)
  const [showFailAlert, setShowFailAlert] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [createLicenseTypeMutation, { loading: createLoading }] = useCreateLicenseTypeMutation()
  const [updateLicenseTypeMutation, { loading: updateLoading }] = useUpdateLicenseTypeMutation()
  const history = useHistory()
  const { id } = useParams<CreateOrUpdateTypeParams>()
  const { data: existing } = useLicenseTypeQuery({ skip: !id, variables: { id: id || '' } })
  const [
    addLicenseTypeLabel,
    updateLicenseTypeLabel,
    createLabel,
    updateLabel,
    typeKeyLabel,
    maximumActivationsLabel,
    descriptionLabel,
    creationFailedLabel,
    creationSucceededLabel,
  ] = useMemo(() => {
    return [
      t('licenseTypes.add'),
      t('licenseTypes.update'),
      t('actions.create'),
      t('actions.update'),
      t('licenseTypes.key') + '*',
      t('licenses.maximumActivations') + '*',
      t('licenseTypes.description'),
      t('licenseTypes.creationFailed'),
      t('licenseTypes.creationSucceeded'),
    ]
  }, [t])

  useEffect(() => {
    if (existing) {
      setFormValues({
        typeKey: existing.licenseType?.type_key || '',
        maximumActivations: `${
          existing.licenseType?.activation_maximum ? `${existing.licenseType?.activation_maximum}` : ''
        }`,
        description: existing.licenseType?.description ?? '',
      })
    }
  }, [existing])

  const createLicenseType = useCallback(() => {
    if (!formValues.typeKey || !formValues.maximumActivations) {
      setShowFailAlert(true)
      return
    }

    if (id) {
      updateLicenseTypeMutation({
        variables: {
          id,
          licenseType: {
            type_key: formValues.typeKey,
            description: formValues.description,
            activation_maximum: parseInt(formValues.maximumActivations),
          },
        },
      })
        .then(() => {
          history.push(ROUTES.licenseTypes)
        })
        .catch(() => {
          setShowFailAlert(true)
        })
      return
    }

    createLicenseTypeMutation({
      variables: {
        licenseType: {
          type_key: formValues.typeKey,
          description: formValues.description,
          activation_maximum: parseInt(formValues.maximumActivations),
        },
      },
    })
      .then(() => {
        history.push(ROUTES.licenseTypes)
      })
      .catch(() => {
        setShowFailAlert(true)
      })
  }, [id, updateLicenseTypeMutation, createLicenseTypeMutation, formValues, history])

  return (
    <>
      <FixedActionBar
        labels={[id ? updateLabel : createLabel]}
        actions={[createLicenseType]}
        icons={[Save]}
        buttonProps={[{ solid: true }]}
      />
      <ResultAlert
        alertText={creationFailedLabel}
        showAlert={showFailAlert}
        modifyShowAlert={setShowFailAlert}
        severity="warning"
      />
      <ResultAlert
        alertText={creationSucceededLabel}
        showAlert={showSuccessAlert}
        modifyShowAlert={setShowSuccessAlert}
      />
      <PageHeading title={id ? updateLicenseTypeLabel : addLicenseTypeLabel} />
      <Grid container spacing={3} style={{ paddingTop: 20 }}>
        <Grid item xs={12}>
          <ProgressBar show={createLoading || updateLoading} />
        </Grid>
        <Text
          value={formValues.typeKey}
          label={typeKeyLabel}
          columns={12}
          changeHandler={(typeKey) =>
            setFormValues((formValues) => {
              return { ...formValues, typeKey }
            })
          }
        />
        <Text
          value={formValues.maximumActivations}
          label={maximumActivationsLabel}
          columns={12}
          onlyNumbers
          changeHandler={(maximumActivations) =>
            setFormValues((formValues) => {
              return { ...formValues, maximumActivations }
            })
          }
        />
        <Text
          value={formValues.description}
          label={descriptionLabel}
          columns={12}
          changeHandler={(description) =>
            setFormValues((formValues) => {
              return { ...formValues, description }
            })
          }
        />

        {id && <JournalTable showTitle filter={{ log_key: id }} />}
      </Grid>
    </>
  )
}
