import React from 'react'
import { useTranslation } from 'react-i18next'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'
import Alert from '@material-ui/lab/Alert'

export type ResultAlertProps = {
  alertText: string
  showAlert: boolean
  modifyShowAlert: (showAlert: boolean) => void
  severity?: 'error' | 'warning' | 'success' | 'info'
}

export const ResultAlert: React.FC<ResultAlertProps> = ({ showAlert, severity, modifyShowAlert, alertText }) => {
  const { t } = useTranslation('common')

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={showAlert}
      autoHideDuration={7000}
      onClose={() => modifyShowAlert(false)}
    >
      <Alert onClose={() => modifyShowAlert(false)} severity={severity ?? 'success'}>
        {t(alertText)}
      </Alert>
    </Snackbar>
  )
}
