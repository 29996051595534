import React, { FC, ReactElement } from 'react'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

type PageHeadingProps = {
  title: string
}

export const PageHeading: FC<PageHeadingProps> = React.memo(
  ({ title }): ReactElement => {
    const { t } = useTranslation('common')

    return (
      <>
        <Typography color="secondary" style={{ paddingBottom: 20 }} variant={'h4'}>
          {t(title)}
        </Typography>
      </>
    )
  },
)
PageHeading.displayName = 'PageHeading'
