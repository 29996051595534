import React, { FC, ReactElement, useCallback, useMemo, useState } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import {
  LicensePoolFilter,
  LicensePoolPageEntry,
  PaginationParams,
  useDeleteLicensePoolMutation,
  useLicensePoolsQuery,
  useLicenseTypesQuery,
} from '../../../../api/models'
import { DataTableColumn, DataTableRow } from '../../partials/DataTable/DataTableTypes'
import { PageHeading } from '../../partials/PageHeading'
import { Grid } from '@material-ui/core'
import { PaginationInfoLimitUpdater } from '../../partials/Pagination/PaginationInfoLimitUpdater'
import Search from '../../partials/Inputs/Search'
import { DataTable } from '../../partials/DataTable/DataTable'
import { LicenseServerPagination } from '../../partials/Pagination/LicenseServerPagination'
import { toLocaleDateTime } from '../../../helpers/dateTime'
import { Dropdown } from '../../partials/Inputs/Dropdown'
import { DatePicker } from '../../partials/Inputs/DatePicker/DatePicker'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { generatePath, useHistory } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes'
import { CsButton, FixedActionBar } from '@csinstruments/cs-react-theme'
import { getTokens, hasUserRoles } from '../../../helpers/keycloakService'
import { StandardConfirmationModal } from '../../partials/StandardConfirmationModal/StandardConfirmationModal'
import { Add } from '@material-ui/icons'
import { useRecoilState } from 'recoil'
import { paginationState } from '../../../helpers/recoil'

const poolsToTableRows = (
  pools:
    | ({ __typename?: 'LicensePoolPageEntry' | undefined } & Pick<
        LicensePoolPageEntry,
        | 'creator_user_mail'
        | 'date_created'
        | 'date_deleted'
        | 'id'
        | 'license_type_key'
        | 'n_available'
        | 'n_in_use'
        | 'note'
        | 'pool_size'
      >)[]
    | undefined,
): DataTableRow[] => {
  return (
    pools?.map((p) => {
      return {
        id: p.id,
        values: [
          p.id,
          p.license_type_key || '',
          p.creator_user_mail,
          p.pool_size ? `${p.pool_size}` : '∞',
          p.n_in_use ? `${p.n_in_use}` : '0',
          p.n_available || p.n_available === 0 ? `${p.n_available}` : '∞',
          `${toLocaleDateTime(p.date_created)}`,
        ],
      }
    }) || []
  )
}

export const ListLicensePools: FC = ({}): ReactElement => {
  const { t } = useTranslation()
  const [paginationData, setPaginationData] = useState<PaginationParams>({ limit: 100, Offset: 0 })
  const [searchTerm, setSearchTerm] = useState('')
  const [preliminaryFilter, setPreliminaryFilter] = useState<LicensePoolFilter>({})
  const [filter, setFilter] = useState<LicensePoolFilter>({})
  const history = useHistory()
  const [deleteMutation] = useDeleteLicensePoolMutation()
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedID, setSelectedID] = useState('')
  const [_, setCurrentPage] = useRecoilState(paginationState)
  const { data, refetch } = useLicensePoolsQuery({
    variables: { paginationParams: paginationData, filter: filter },
  })
  const { data: licenseTypesData } = useLicenseTypesQuery({
    variables: {},
  })

  const licenseTypeNames = useMemo(
    () => ['-', ...(licenseTypesData?.licenseTypes.licenseTypes.map((lt) => lt.type_key) || [])],
    [licenseTypesData],
  )

  const deletionConfirmed = useCallback(() => {
    deleteMutation({ variables: { id: selectedID } }).then(() => refetch())
  }, [deleteMutation, selectedID, refetch])

  const [
    licensePoolsLabel,
    searchLabel,
    idLabel,
    licenseTypeLabel,
    creatorLabel,
    nLicensesLabel,
    grantedLicensesLabel,
    availableLicensesLabel,
    createdAtLabel,
    dateFromLabel,
    dateUntilLabel,
    filterLabel,
  ] = useMemo(() => {
    return [
      t('objects.licensePools'),
      t('actions.search'),
      t('objects.id'),
      t('objects.licenseType'),
      t('licensePools.creator'),
      t('licensePools.nLicenses'),
      t('licensePools.grantedLicenses'),
      t('licensePools.availableLicenses'),
      t('licensePools.createdAt'),
      t('licensePools.dateFrom'),
      t('licensePools.dateUntil'),
      t('actions.filter'),
    ]
  }, [t])

  const [token] = getTokens()
  const masterUser = useMemo(() => {
    return hasUserRoles(token).master
  }, [token])

  const columns: DataTableColumn[] = useMemo(
    () => [
      { label: idLabel },
      { label: licenseTypeLabel },
      { label: creatorLabel },
      { label: nLicensesLabel },
      { label: grantedLicensesLabel },
      { label: availableLicensesLabel },
      { label: createdAtLabel },
    ],
    [
      idLabel,
      licenseTypeLabel,
      creatorLabel,
      nLicensesLabel,
      createdAtLabel,
      grantedLicensesLabel,
      availableLicensesLabel,
    ],
  )

  const rows: DataTableRow[] = useMemo(() => poolsToTableRows(data?.licensePools.entries), [data?.licensePools.entries])

  const searchTermChanged = useCallback((term: string) => {
    setSearchTerm(term)
    setCurrentPage(1)
  }, [])

  return (
    <>
      <StandardConfirmationModal
        handleClose={() => setModalOpen(false)}
        open={modalOpen}
        text={t('modals.standardText')}
        handleConfirm={deletionConfirmed}
      />
      {masterUser && (
        <FixedActionBar
          labels={[t('actions.add')]}
          actions={[() => history.push(ROUTES.addLicensePool)]}
          icons={[Add]}
          buttonProps={[{ solid: true }]}
        />
      )}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <PageHeading title={licensePoolsLabel} />
        <Grid container spacing={3} style={{ paddingTop: 20 }}>
          <Grid item xs={12} md={6}>
            <PaginationInfoLimitUpdater paginationParams={paginationData} setPaginationParams={setPaginationData} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Search label={searchLabel} changeFunction={searchTermChanged} />
          </Grid>
          <Grid item xs={6} sm={4}>
            <Dropdown
              valueSelected={(s) =>
                s.length >= 1 &&
                setPreliminaryFilter((filter) => {
                  return { ...filter, type_key: s[0] === '-' ? undefined : s[0] }
                })
              }
              values={licenseTypeNames}
              label={licenseTypeLabel}
            />
          </Grid>
          <DatePicker
            xs={4}
            label={dateFromLabel}
            onChange={(date) =>
              setPreliminaryFilter((filter) => {
                return { ...filter, date_from: date.getTime() / 1000 }
              })
            }
          />
          <DatePicker
            xs={4}
            label={dateUntilLabel}
            onChange={(date) =>
              setPreliminaryFilter((filter) => {
                return { ...filter, date_until: date.getTime() / 1000 }
              })
            }
          />
          <Grid item xs={4}>
            <CsButton solid={true} onClick={() => setFilter(preliminaryFilter)}>
              {filterLabel}
            </CsButton>
          </Grid>
          <Grid item xs={12}>
            <DataTable
              columns={columns}
              filterString={searchTerm}
              onButtonClicked={(id, action) => {
                if (action === 'grantLicense') {
                  history.push(generatePath(ROUTES.grantLicenseForPool, { poolID: id }))
                } else if (action === 'delete') {
                  setModalOpen(true)
                  setSelectedID(id)
                } else if (action === 'edit') {
                  history.push(generatePath(ROUTES.editLicensePool, { id }))
                }
              }}
              rows={rows}
              allowedActions={{
                sortable: true,
                licenseGrantable: !masterUser,
                deletable: masterUser,
                editable: masterUser,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <LicenseServerPagination
              paginationParams={paginationData}
              setPaginationParams={setPaginationData}
              nTotal={data?.licensePools.total || 0}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </>
  )
}
