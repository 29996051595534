function descendingComparator(a: DataTableRow, b: DataTableRow, orderBy: number, numeric: boolean): number {
  const aVal = numeric ? parseFloat(a.values[orderBy].split(' ')[0]) : a.values[orderBy]
  const bVal = numeric ? parseFloat(b.values[orderBy].split(' ')[0]) : b.values[orderBy]

  if (bVal < aVal) {
    return -1
  }
  if (bVal > aVal) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'
type DataTableRow = {
  id: string
  values: string[]
}

export function getComparator(
  order: Order,
  orderBy: number,
  numeric: boolean,
): (a: DataTableRow, b: DataTableRow) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, numeric)
    : (a, b) => -descendingComparator(a, b, orderBy, numeric)
}

export function stableSort(
  array: DataTableRow[],
  comparator: (a: DataTableRow, b: DataTableRow) => number,
): DataTableRow[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [DataTableRow, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}
