import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { FixedActionBar } from '@csinstruments/cs-react-theme'
import { Save } from '@material-ui/icons'
import { ResultAlert } from '../../partials/ResultAlert'
import { useTranslation } from '../../../hooks/useTranslation'
import { PageHeading } from '../../partials/PageHeading'
import {
  useCreateLicensePoolMutation,
  useGroupsQuery,
  useLicensePoolQuery,
  useLicenseTypesQuery,
  useUpdateLicensePoolMutation,
  useUserOfGroupQuery,
} from '../../../../api/models'
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core'
import { Text } from '../../partials/Inputs/Text'
import { Dropdown } from '../../partials/Inputs/Dropdown'
import { useHistory, useParams } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes'
import { JournalTable } from '../Journal/JournalTable'
import { ProgressBar } from '../../partials/ProgressBar/ProgressBar'

type CreateLicensePoolFormValues = {
  licenseType?: string
  nLicenses?: string
  infiniteLicenses?: boolean
  group?: string
  note?: string
  orderNumber?: string
}

type CreateOrEditPoolParams = {
  id?: string
  groupID?: string
  userID?: string
}

const initialFormValues: CreateLicensePoolFormValues = {
  licenseType: '',
  nLicenses: '',
  group: '',
  note: '',
  infiniteLicenses: false,
  orderNumber: '',
}

export const CreateOrUpdateLicensePool: FC = ({}): ReactElement => {
  const { t } = useTranslation()
  const [showFailAlert, setShowFailAlert] = useState(false)
  const [formValues, setFormValues] = useState<CreateLicensePoolFormValues>(initialFormValues)
  const [createLicensePoolMutation, { loading: createLoading }] = useCreateLicensePoolMutation()
  const [updateLicensePoolMutation, { loading: updateLoading }] = useUpdateLicensePoolMutation()
  const history = useHistory()
  const { id, groupID, userID } = useParams<CreateOrEditPoolParams>()
  const { data: userOfGroupData, loading: userLoading } = useUserOfGroupQuery({
    variables: { id: userID || '' },
    skip: !userID,
  })
  const { data: groupData, loading: groupLoading } = useGroupsQuery({
    variables: {},
  })
  const [
    createLabel,
    updateLabel,
    creationFailedLabel,
    addLicensePoolLabel,
    updateLicensePoolLabel,
    nLicensesLabel,
    licenseTypeLabel,
    infiniteLicensesLabel,
    groupLabel,
    noteLabel,
    orderNumberLabel,
  ] = useMemo(() => {
    return [
      t('actions.create'),
      t('actions.update'),
      t('licensePools.creationFailed'),
      t('licensePools.add'),
      t('licensePools.update'),
      t('licensePools.nLicenses') + '*',
      t('objects.licenseType') + '*',
      t('licensePools.infiniteLicenses'),
      t('licensePools.group') + '*',
      t('objects.note'),
      t('licensePools.orderNumber'),
    ]
  }, [t])

  const { data: existing } = useLicensePoolQuery({ skip: !id, variables: { id: id || '' } })
  const { data: licenseTypesData } = useLicenseTypesQuery({
    variables: {},
  })

  const licenseTypeNames = useMemo(() => licenseTypesData?.licenseTypes.licenseTypes.map((lt) => lt.type_key) || [], [
    licenseTypesData,
  ])

  useEffect(() => {
    if (existing) {
      setFormValues({
        infiniteLicenses: !existing.licensePool.pool_size,
        nLicenses: `${existing.licensePool.pool_size ?? ''}`,
        orderNumber: `${existing.licensePool.order_number ?? ''}`,
        note: `${existing.licensePool.note ?? ''}`,
        group: groupData?.groups.find((g) => g.id === existing.licensePool.group_id)?.name || '',
        licenseType: `${existing.licensePool.license_type?.type_key ?? ''}`,
      })
    } else if (groupID) {
      setFormValues((fv) => {
        return {
          ...fv,
          group: groupData?.groups.find((g) => g.id === groupID)?.name || '',
        }
      })
    } else if (userOfGroupData?.userOfGroup?.group?.id) {
      setFormValues((fv) => {
        return {
          ...fv,
          group: groupData?.groups.find((g) => g.id === userOfGroupData?.userOfGroup?.group?.id)?.name || '',
        }
      })
    }
  }, [existing, groupData?.groups, groupID, userOfGroupData?.userOfGroup?.group?.id])

  const createLicensePool = useCallback(() => {
    if (!(formValues.nLicenses || formValues.infiniteLicenses) /*|| !formValues.userMail*/ || !formValues.licenseType) {
      setShowFailAlert(true)
      return
    }
    const lt = licenseTypesData?.licenseTypes.licenseTypes.find((lt) => lt.type_key === formValues.licenseType)
    if (!lt || !groupData) {
      setShowFailAlert(true)
      return
    }

    const group_id = groupData.groups.find((g) => g.name === formValues.group)?.id || ''
    if (!group_id) {
      setShowFailAlert(true)
      return
    }

    if (id) {
      updateLicensePoolMutation({
        variables: {
          id,
          licensePool: {
            license_type_id: lt.id,
            note: formValues.note || null,
            group_id,
            pool_size: formValues.infiniteLicenses ? null : parseInt(formValues.nLicenses || '0'),
            order_number: formValues.orderNumber || null,
          },
        },
      })
        .then(() => {
          history.push(ROUTES.licensePools)
        })
        .catch(() => {
          setShowFailAlert(true)
        })
      return
    }

    createLicensePoolMutation({
      variables: {
        licensePool: {
          license_type_id: lt.id,
          note: formValues.note || null,
          group_id,
          pool_size: formValues.infiniteLicenses ? null : parseInt(formValues.nLicenses || '0'),
          order_number: formValues.orderNumber || null,
        },
      },
    })
      .then(() => {
        history.push(ROUTES.licensePools)
      })
      .catch(() => {
        setShowFailAlert(true)
      })
  }, [
    groupData,
    id,
    updateLicensePoolMutation,
    history,
    formValues,
    createLicensePoolMutation,
    licenseTypesData?.licenseTypes.licenseTypes,
  ])

  return (
    <>
      <FixedActionBar
        labels={[id ? updateLabel : createLabel]}
        actions={[createLicensePool]}
        icons={[Save]}
        buttonProps={[{ solid: true }]}
      />
      <ResultAlert
        alertText={creationFailedLabel}
        showAlert={showFailAlert}
        modifyShowAlert={setShowFailAlert}
        severity="warning"
      />
      <PageHeading title={id ? updateLicensePoolLabel : addLicensePoolLabel} />
      <Grid container spacing={3} style={{ paddingTop: 20 }}>
        <Grid item xs={12}>
          <ProgressBar show={createLoading || updateLoading || userLoading || groupLoading} />
        </Grid>
        <Grid item xs={12}>
          <Dropdown
            valueSelected={(v) =>
              v.length > 0 &&
              setFormValues((values) => {
                return { ...values, licenseType: v[0] }
              })
            }
            values={licenseTypeNames}
            label={licenseTypeLabel}
            preselect={licenseTypeNames.length ? [licenseTypeNames[0]] : undefined}
          />
        </Grid>
        <Text
          label={nLicensesLabel}
          onlyNumbers={true}
          value={formValues.nLicenses}
          displayOnly={formValues.infiniteLicenses}
          columns={8}
          changeHandler={(v) =>
            setFormValues((values) => {
              return { ...values, nLicenses: v }
            })
          }
        />
        <Grid item xs={4}>
          <FormControlLabel
            control={<Checkbox checked={formValues.infiniteLicenses || false} />}
            label={infiniteLicensesLabel}
            value={formValues.infiniteLicenses || false}
            style={{ paddingTop: 12, margin: 'auto' }}
            onChange={(e, checked) =>
              setFormValues((values) => {
                return { ...values, infiniteLicenses: checked, nLicenses: '' }
              })
            }
          />
        </Grid>
        <Text
          label={noteLabel}
          columns={12}
          value={formValues.note}
          changeHandler={(v) =>
            setFormValues((values) => {
              return { ...values, note: v }
            })
          }
        />
        <Text
          label={orderNumberLabel}
          columns={12}
          value={formValues.orderNumber}
          changeHandler={(v) =>
            setFormValues((values) => {
              return { ...values, orderNumber: v }
            })
          }
        />
        <Grid item xs={12}>
          {groupData?.groups && !userLoading && !groupLoading && (
            <Dropdown
              preselect={formValues.group ? [formValues.group] : []}
              label={groupLabel}
              values={groupData?.groups.map((g) => g.name) || []}
              valueSelected={(s) =>
                setFormValues((fv) => {
                  return { ...fv, group: s.length ? s[0] : '' }
                })
              }
            />
          )}
        </Grid>
        {id && <JournalTable showTitle filter={{ license_pool_id: id }} />}
      </Grid>
    </>
  )
}
