import React, { FC, ReactElement, useMemo } from 'react'
import { useGroupsQuery } from '../../../../api/models'
import { DataTableColumn, DataTableRow } from '../../partials/DataTable/DataTableTypes'
import { useTranslation } from '../../../hooks/useTranslation'
import { PageHeading } from '../../partials/PageHeading'
import { Grid, Typography } from '@material-ui/core'
import { ProgressBar } from '../../partials/ProgressBar/ProgressBar'
import { DataTable } from '../../partials/DataTable/DataTable'
import { ROUTES } from '../../../helpers/routes'
import { generatePath, useHistory } from 'react-router-dom'

export const GroupsOverview: FC = ({}): ReactElement => {
  const { t } = useTranslation()
  const history = useHistory()
  const { data, loading } = useGroupsQuery({
    variables: {},
  })
  const columns: DataTableColumn[] = useMemo(() => [{ label: t('objects.id') }, { label: t('objects.name') }], [t])
  const rows: DataTableRow[] = useMemo(() => {
    if (!data?.groups) {
      return []
    }

    return data.groups.map((g) => {
      return { id: g.id, values: [g.id, g.name] }
    })
  }, [data?.groups])

  return (
    <>
      <PageHeading title={t('objects.groups')} />
      <Grid container spacing={3} style={{ paddingTop: 20 }}>
        <Grid item xs={12}>
          <ProgressBar show={loading} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2">{t('users.keycloakHint')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <DataTable
            columns={columns}
            onButtonClicked={(id, action) => {
              if (action === 'view') {
                history.push(generatePath(ROUTES.groupUsers, { groupID: id }))
              } else if (action === 'grantLicense') {
                history.push(generatePath(ROUTES.addLicensePoolForGroup, { groupID: id }))
              }
            }}
            rows={rows}
            allowedActions={{ sortable: true, viewable: true, licenseGrantable: true }}
          />
        </Grid>
      </Grid>
    </>
  )
}
