import { Dialog, DialogContent, DialogActions, Grid, Button } from '@material-ui/core'
import React, { useCallback } from 'react'
import { getApolloClient } from '../../helpers/apolloClient'
import { keycloakLogout } from '../../helpers/keycloakService'
import { NotAllowed } from '../pages/NotAllowed'

const AccessDenied: React.FC = () => {
  const clickConfirm = useCallback(() => {
    keycloakLogout()
    getApolloClient().resetStore()
  }, [])

  return (
    <>
      <Dialog
        open
        onClose={clickConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <NotAllowed />
        </DialogContent>
        <DialogActions>
          <Grid container justify="space-evenly" spacing={2}>
            <Grid item>
              <Button onClick={clickConfirm} variant="contained" color="secondary">
                {'ok'}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AccessDenied
