import React from 'react'
import EditIcon from '@material-ui/icons/EditOutlined'
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined'
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestoreOutlined'
import DeleteForeverIcon from '@material-ui/icons/DeleteForeverOutlined'
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined'
import { DataItemAction } from './DataTableTypes'
import { useTranslation } from 'react-i18next'
import { CsButton } from '@csinstruments/cs-react-theme'
import { Add, AddToPhotos } from '@material-ui/icons'

export type DataTableButtonProps = {
  condition?: boolean
  title: string
  onButtonClicked?: (id: string) => void
  id: string
  action: DataItemAction
}

export const DataTableButton: React.FC<DataTableButtonProps> = React.memo(
  ({ condition, action, title, id, onButtonClicked }) => {
    const { t } = useTranslation('common')

    return (
      <>
        {condition && (
          <CsButton
            style={{ marginLeft: '10px !important', marginBottom: '5px !important' }}
            title={t(title)}
            id={id}
            color={'primary'}
            small
            textButton
            onClick={onButtonClicked}
          >
            {action === 'edit' && <EditIcon />}
            {action === 'view' && <VisibilityIcon />}
            {action === 'restore' && <SettingsBackupRestoreIcon />}
            {action === 'delete' && <DeleteForeverIcon />}
            {action === 'generate' && <DescriptionIcon />}
            {action === 'grantLicense' && <Add />}
            {action === 'batchGrantLicense' && <AddToPhotos />}
          </CsButton>
        )}
      </>
    )
  },
)

DataTableButton.displayName = 'DataTableButton'

export default DataTableButton
