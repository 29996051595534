import { atom } from 'recoil'

export const themeState = atom({
  key: 'ThemeState',
  default: 'csLight' as 'csLight' | 'neutralLight' | 'bekoLight' | 'neutralLsLight',
})
export const paginationState = atom({
  key: 'PaginationState',
  default: 1,
})
