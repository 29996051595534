import React, { FC, ReactElement } from 'react'
import { PageHeading } from '../../partials/PageHeading'
import { useTranslation } from '../../../hooks/useTranslation'
import { Typography } from '@material-ui/core'

export const NotAllowed: FC = ({}): ReactElement => {
  const { t } = useTranslation()

  return (
    <>
      <PageHeading title={t('error.access_denied')} />
      <Typography variant="body1">{t('error.access_denied_message')}</Typography>
    </>
  )
}
