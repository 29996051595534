import React from 'react'
import { CsButtonProps } from '@csinstruments/cs-react-theme/dist/components/Buttons/CsButton'
import { SvgIcon } from '@material-ui/core'
import { CsButton } from '@csinstruments/cs-react-theme'
import { base64ToText } from '../../../helpers/fileUpload'

type SingleFileUploadProps = {
  label: string
  icon: typeof SvgIcon
  acceptedType: string
  changeHandler: (contents: string) => void
  buttonProps?: CsButtonProps
}

export const SingleFileSelectButton: React.FC<SingleFileUploadProps> = ({
  label,
  icon,
  changeHandler,
  acceptedType,
  buttonProps,
}) => {
  const handleChange = (selectorFiles: FileList | null): void => {
    if (selectorFiles && selectorFiles.length > 0) {
      const reader = new FileReader()

      const file = selectorFiles.item(0)
      if (file) {
        reader.readAsDataURL(file)

        reader.onload = () => {
          const resultString = reader.result as string
          const { result, error } = base64ToText(resultString)
          if (!error) {
            changeHandler(result || '')
          }
        }
      } else {
        changeHandler('')
      }
    }
  }

  buttonProps = buttonProps ?? {}

  return (
    <>
      <input
        accept={acceptedType}
        style={{ display: 'none' }}
        id={`file-input-${label}`}
        multiple
        type="file"
        onChange={(e) => {
          handleChange(e.target.files)
          e.target.value = ''
        }}
      />
      <label htmlFor={`file-input-${label}`}>
        <CsButton fileUpload={true} fullwidth={true} label={label} {...buttonProps}>
          {React.createElement(icon)}
        </CsButton>
      </label>
    </>
  )
}
