import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { NavigationEntry } from './types'
import { TFunction } from 'i18next'
import ListItem from '@material-ui/core/ListItem'
import { listItem, listItemOpen } from './NavigationDrawerStyles'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import ListItemText from '@material-ui/core/ListItemText'
import { Typography } from '@material-ui/core'
import React from 'react'

export function getNavigationEntry(
  classes: ClassNameMap<
    | 'drawerButton'
    | 'drawerClose'
    | 'paper'
    | 'drawerText'
    | 'drawer'
    | 'drawerTextSelected'
    | 'profileButton'
    | 'drawerOpen'
    | 'selected'
  >,
  selected: boolean,
  entry: NavigationEntry,
  onLinkClicked: (link: string) => void,
  open: boolean,
  t: TFunction,
): JSX.Element {
  return (
    <ListItem
      classes={{ selected: classes.selected }}
      selected={selected}
      button
      key={entry.text}
      onClick={() => onLinkClicked(entry.link)}
      style={open ? listItemOpen : listItem}
    >
      <ListItemIcon>
        {!open && (
          <Tooltip placement={'right-start'} title={t(entry.text) as string}>
            <entry.Icon className={selected ? classes.drawerTextSelected : classes.drawerText} />
          </Tooltip>
        )}
        {open && <entry.Icon className={selected ? classes.drawerTextSelected : classes.drawerText} />}
      </ListItemIcon>
      <ListItemText
        disableTypography
        primary={open ? <Typography variant={'subtitle1'}>{'\u00A0' + t(entry.text)}</Typography> : undefined}
      />
    </ListItem>
  )
}
