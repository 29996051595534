import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '.MuiPickersCalendarHeader-transitionContainer': {
        position: 'static',
        paddingTop: 50,
        verticalAlign: '',
      },
      '.MuiPickersCalendarHeader-transitionContainer > .MuiTypography-alignCenter': {
        position: 'absolute',
        top: 10,
      },
    },
  }),
)
