import React from 'react'
import { DataTableHeadProps } from './DataTableTypes'
import { useTranslation } from 'react-i18next'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import TableSortLabel from '@material-ui/core/TableSortLabel'

export const DataTableHead: React.FC<DataTableHeadProps> = ({
  columns,
  onSelectAllClick,
  doSort,
  sortable,
  checkable,
}) => {
  const { t } = useTranslation('common')
  const [allSelected, setAllSelected] = React.useState<boolean>(false)
  const sortDescendingInitial = columns.map(() => false)
  const [sortDescending, setSortDescending] = React.useState<boolean[]>(sortDescendingInitial)

  const clicked = (): void => {
    onSelectAllClick(!allSelected)
    setAllSelected(!allSelected)
  }

  const sortBy = (id: number): void => {
    doSort(id, sortDescending[id] ? 'desc' : 'asc')
    sortDescending[id] = !sortDescending[id]
    setSortDescending(sortDescending)
  }

  return (
    <TableHead>
      <TableRow>
        {checkable && (
          <TableCell padding="checkbox">
            <Checkbox
              disableRipple
              onClick={clicked}
              checked={allSelected}
              inputProps={{ 'aria-label': 'select all' }}
            />
          </TableCell>
        )}

        {columns.map((col, i) => {
          const cellContent = sortable ? (
            <TableSortLabel direction={sortDescending[i] ? 'desc' : 'asc'} onClick={() => sortBy(i)}>
              <b>{t(col.label)}</b>
            </TableSortLabel>
          ) : (
            <b>{t(col.label)}</b>
          )
          return (
            <TableCell key={col.label} align={col.alignment === 'right' ? 'right' : 'left'}>
              {cellContent}
            </TableCell>
          )
        })}
        <TableCell align="right" />
      </TableRow>
    </TableHead>
  )
}

export default DataTableHead
