import React, { FC, ReactElement } from 'react'
import { PaginationParams } from '../../../../api/models'
import { useTranslation } from '../../../hooks/useTranslation'
import { InputLabel, MenuItem, Select } from '@material-ui/core'

type PaginationInfoLimitUpdaterProps = {
  paginationParams: PaginationParams
  setPaginationParams: React.Dispatch<React.SetStateAction<PaginationParams>>
}

export const PaginationInfoLimitUpdater: FC<PaginationInfoLimitUpdaterProps> = ({
  paginationParams,
  setPaginationParams,
}): ReactElement => {
  const { t } = useTranslation()

  const nEntriesLabel = t('pagination.nEntries')

  return (
    <>
      <InputLabel id="pagination-updater">{nEntriesLabel}</InputLabel>
      <Select
        labelId="pagination-updater"
        value={paginationParams.limit || 0}
        onChange={(val) =>
          setPaginationParams((paginationParams) => {
            return { ...paginationParams, limit: val.target.value as number }
          })
        }
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={25}>25</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>
    </>
  )
}
