import React, { FC, ReactElement, useCallback } from 'react'
import { Badge, Box, Grid, SvgIcon, Typography } from '@material-ui/core'
import { cardDefault } from '../../../../styles/shared'
import { useHistory } from 'react-router-dom'

type DashboardTileProps = {
  title: string
  subtitle: string
  icon: typeof SvgIcon
  number: number
  link: string
}

export const DashboardTile: FC<DashboardTileProps> = (props): ReactElement => {
  const history = useHistory()

  const clickAction = useCallback(() => {
    history.push(props.link)
  }, [props.link, history])

  return (
    <Grid item xs={12} sm={6} lg={3} xl={2} onClick={clickAction}>
      <Box border={1} borderRadius={8} borderColor="primary" style={cardDefault}>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Typography variant={'h5'}>{props.title}</Typography>
          <div style={{ paddingTop: 10 }}>
            <Badge
              badgeContent={`${props.number}`}
              color="primary"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <props.icon fontSize={'large'} />
            </Badge>
          </div>
          <Typography variant={'subtitle1'} style={{ paddingTop: 20 }}>
            {props.subtitle}
          </Typography>
        </div>
      </Box>
    </Grid>
  )
}
