import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { LicenseFilterUpdater as LicenseFilter } from '../../../../api/models'
import { Grid } from '@material-ui/core'
import { useTranslation } from '../../../hooks/useTranslation'
import { CsButton } from '@csinstruments/cs-react-theme'
import Search from '../../partials/Inputs/Search'
import { useCookies } from 'react-cookie'

type Props = {
  setFilter: (value: ((prevState: LicenseFilter) => LicenseFilter) | LicenseFilter) => void
}

export const LicenseFilterUpdater: FC<Props> = ({ setFilter }): ReactElement => {
  const [cookies, setCookie] = useCookies(['filter'])
  const [newFilter, setNewFilter] = useState<LicenseFilter>({ fulltext: cookies?.filter?.fulltext || '' })
  const { t } = useTranslation()

  useEffect(() => {
    if (newFilter.fulltext) {
      setFilter(newFilter)
    }
  }, [])

  const [
    merchantsLabel,
    customersLabel,
    licenseKeyLabel,
    filterLabel,
    dateFromLabel,
    dateUntilLabel,
    searchLabel,
  ] = useMemo(() => {
    return [
      t('objects.merchants'),
      t('objects.customers'),
      t('licenses.licenseKey'),
      t('actions.filter'),
      t('licenses.dateFrom'),
      t('licenses.dateUntil'),
      t('actions.search'),
    ]
  }, [t])

  const searchTermChanged = useCallback((term: string) => {
    setNewFilter((filter) => {
      return { ...filter, fulltext: term.trim() }
    })
  }, [])

  const handleFilter = () => {
    setCookie('filter', newFilter, { path: '/' })
    setFilter(newFilter)
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Search label={searchLabel} changeFunction={searchTermChanged} />
        </Grid>
        <Grid item xs={4}>
          <CsButton solid={true} onClick={() => handleFilter()}>
            {filterLabel}
          </CsButton>
        </Grid>
      </Grid>
    </>
  )
}
