import React, { FC, ReactElement, useMemo, useState } from 'react'
import { JournalFilter } from '../../../../api/models'
import { Grid } from '@material-ui/core'
import { Dropdown } from '../../partials/Inputs/Dropdown'
import { useTranslation } from '../../../hooks/useTranslation'
import { CsButton } from '@csinstruments/cs-react-theme'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker } from '../../partials/Inputs/DatePicker/DatePicker'

type Props = {
  setFilter: (value: ((prevState: JournalFilter) => JournalFilter) | JournalFilter) => void
}

export const JournalFilterUpdater: FC<Props> = ({ setFilter }): ReactElement => {
  const [newFilter, setNewFilter] = useState<JournalFilter>({})
  const { t } = useTranslation()

  const [typeLabel, dateFromLabel, dateUntilLabel, filterLabel] = useMemo(() => {
    return [t('objects.type'), t('licenses.dateFrom'), t('licenses.dateUntil'), t('actions.filter')]
  }, [t])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid item xs={4}>
        <Dropdown
          label={typeLabel}
          values={['Alle', 'CREATE', 'UPDATE', 'DELETE']}
          valueSelected={(s) =>
            setNewFilter((filter) => {
              return { ...filter, type: s.length > 0 && s[0] !== 'Alle' ? s[0] : undefined }
            })
          }
        />
      </Grid>
      <DatePicker
        xs={4}
        label={dateFromLabel}
        onChange={(date) =>
          setNewFilter((filter) => {
            return { ...filter, date_from: date.getTime() / 1000 }
          })
        }
      />
      <DatePicker
        xs={4}
        label={dateUntilLabel}
        onChange={(date) =>
          setNewFilter((filter) => {
            return { ...filter, date_until: date.getTime() / 1000 }
          })
        }
      />
      <Grid item xs={4}>
        <CsButton solid={true} onClick={() => setFilter(newFilter)}>
          {filterLabel}
        </CsButton>
      </Grid>
    </MuiPickersUtilsProvider>
  )
}
