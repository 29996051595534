export const toLocaleDateTime = (timestamp: number | undefined | null): string => {
  if (!timestamp) {
    return '-'
  }
  const date = new Date(timestamp * 1000)
  const dateString = date.toLocaleDateString('de-DE')
  const timeString = date.toLocaleTimeString('de-DE')

  return `${dateString} ${timeString}`
}
