import makeStyles from '@material-ui/core/styles/makeStyles'
import { createStyles, Theme } from '@material-ui/core'
import { contrast } from '@csinstruments/cs-react-theme'

const drawerWidth = 240

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.mixins.themeStyle.appBarColor,
      color: contrast().primary,
      borderRight: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: 76,
    },
    drawerButton: {
      padding: '0 !important',
      marginTop: 10,
      // without important, would be overridden…
      backgroundColor: 'transparent !important',
    },
    profileButton: {
      padding: 0,
      marginTop: 16,
      position: 'absolute',
      right: 20,
    },
    drawerText: {
      marginLeft: 3,
      color: contrast().primary,
    },
    drawerTextSelected: {
      marginLeft: 3,
      color: theme.palette.secondary.main,
    },
    selected: {
      '&.Mui-selected': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.secondary.main,
        '&:hover, &:focus': {
          backgroundColor: theme.palette.grey[100],
        },
      },
    },
  }),
)

export const listItemLabel = {
  cursor: 'default',
}

const listItemCommon = {
  height: 50,
  margin: 10,
  borderRadius: 6,
  transition: '0.3s',
}
export const listItemOpen = {
  ...listItemCommon,
  width: 220,
}

export const listItem = {
  ...listItemCommon,
  width: 60,
}

export const drawerTop = {
  paddingTop: 20,
}

export const paddingForDrawer = {
  paddingLeft: 240,
  transition: '0.3s',
}

export const paddingForClosedDrawer = {
  paddingLeft: 74,
  transition: '0.3s',
}
