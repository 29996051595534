import React, { FC, ReactElement, useCallback, useEffect, useRef } from 'react'
import { FormControl, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Autocomplete } from '@material-ui/lab'

type DropdownProps = {
  valueSelected: (value: string[]) => void
  values: string[]
  label: string
  multiple?: boolean
  preselect?: string[]
}

export const Dropdown: FC<DropdownProps> = ({
  valueSelected,
  values,
  preselect = [],
  multiple,
  label,
}): ReactElement => {
  const preselectRef = useRef('unset')
  const { t } = useTranslation('common')

  const valueWasSelected = useCallback(
    (val: string[]) => {
      valueSelected(val)
    },
    [valueSelected],
  )

  useEffect(() => {
    if (preselect && (preselectRef.current === 'unset' || preselectRef.current !== preselect.join(','))) {
      preselectRef.current = preselect.join(',')
      valueWasSelected(preselect)
    }
  }, [preselect, valueWasSelected])

  const defaultValue = multiple ? preselect : preselect && preselect[0]

  return (
    <>
      <FormControl>
        <Autocomplete
          key={(preselect && preselect[0]) || ''}
          fullWidth={true}
          multiple={multiple}
          options={values}
          getOptionLabel={(option) => option}
          style={{ width: 500 }}
          defaultValue={defaultValue || ''}
          renderInput={(params) => <TextField {...params} label={t(label)} />}
          onChange={(event, newValue) => valueWasSelected((multiple ? newValue : [newValue]) as string[])}
        />
      </FormControl>
    </>
  )
}
